








import { Component, Vue, Prop } from 'vue-property-decorator'
import SplitText from '@/components/atoms/SplitText'

@Component({
  components: {
    SplitText,
  },
})
export default class Heading extends Vue {
  @Prop()
  tag!: string

  @Prop()
  split!: string

  @Prop()
  transition!: any
}
