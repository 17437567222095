







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Poster extends Vue {
  @Prop()
  src!: string

  @Prop()
  alt!: string

  @Prop()
  ratio!: number
}
