import { Component, Vue, Prop } from 'vue-property-decorator'
import { SplitText as GsapSplitText } from 'gsap/SplitText'

@Component
export default class SplitText extends Vue {
  @Prop()
  type!: string

  @Prop({
    default: () => ({
      delay: 0,
      stagger: 0,
      reverse: true,
    }),
  })
  transition!: any

  transitionCount = 0

  text!: GsapSplitText // chars, words, lines

  split() {
    this.text = new GsapSplitText(this.$el, {
      type: this.type,
      charsClass: 'char',
      linesClass: 'line',
    })
    this.transitionCount = 0
    this.setup()
  }

  setup() {
    const { lines, chars } = this.text
    const { delay, stagger, reverse } = this.transition

    for (let i = 0; i < lines.length; ++i) {
      const element = lines[i] as HTMLElement
      const transitionDelay = delay + (reverse ? stagger * (lines.length - i - 1) : stagger * i)
      element.style.transitionDelay = `${transitionDelay}s`
      element.addEventListener(this.$css.transitionend, this.onTransitionEnd)
    }

    for (let i = 0; i < chars.length; ++i) {
      const element = chars[i] as HTMLElement
      const transitionDelay = delay + (reverse ? stagger * (chars.length - i - 1) : stagger * i)
      element.style.transitionDelay = `${transitionDelay}s`
      element.addEventListener(this.$css.transitionend, this.onTransitionEnd)
    }
  }

  onTransitionEnd({ target }: any) {
    target.removeEventListener(this.$css.transitionend, this.onTransitionEnd)
    ++this.transitionCount
    if (this.transitionCount === this.text.lines.length) {
      this.transitionCount = 0
      this.text.revert()
    }
  }

  mounted() {
    this.split()
  }

  render() {
    return this.$slots.default
  }
}
