
































import { gsap } from 'gsap'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Paragraph from '@/components/atoms/Paragraph.vue'
import Swiper from '@/components/molecules/Swiper.vue'
import SplitText from '@/components/atoms/SplitText'

@Component({
  components: {
    Paragraph,
    SplitText,
    Swiper,
  },
})
export default class Carousel extends Vue {
  @Prop()
  slides!: any

  @Prop()
  mode!: string

  get carouselSlides() {
    return this.mode === 'random' ? gsap.utils.shuffle(this.slides) : this.slides
  }
}
