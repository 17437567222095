
































import { Component, Vue } from 'vue-property-decorator'
import Swiper from 'swiper'

@Component
export default class SwiperSlider extends Vue {
  swiper!: Swiper

  $refs!: {
    swiper: HTMLElement
    next: HTMLElement
    prev: HTMLElement
  }

  next() {
    this.swiper.slideNext(600)
  }

  prev() {
    this.swiper.slidePrev(600)
  }

  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      loop: true,
      speed: 300,
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      breakpoints: {
        768: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          spaceBetween: 0,
        },
      },
    })
  }
}
