






























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class MoreBtn extends Vue {
  @Prop()
  toggle!: boolean
}
