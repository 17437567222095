

























import { Component, Vue, Prop } from 'vue-property-decorator'
import Carousel from '@/components/molecules/Carousel.vue'
import Display from '@/components/molecules/Display.vue'
import Poster from '@/components/atoms/Poster.vue'

@Component({
  components: {
    Carousel,
    Display,
    Poster,
  },
})
export default class CoverWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  @Prop()
  heading!: string

  @Prop()
  intersect!: boolean

  get hasImage() {
    return !!this.model.image
  }

  get needsCarousel() {
    const { images } = this.model
    return !!images && images.length > 1
  }
}
