









import { Component, Vue, Prop } from 'vue-property-decorator'
import SplitText from '@/components/atoms/SplitText'

@Component({
  components: {
    SplitText,
  },
})
export default class Paragraph extends Vue {
  @Prop({ default: true })
  split!: boolean

  @Prop()
  transition!: any

  innerLinks = [] as any

  link(event: MouseEvent) {
    const target = event.target as HTMLElement
    const href = target.getAttribute('href')
    const split = href ? href.split('#') : []
    const isAnchor = split.length > 1

    if (isAnchor) {
      event.preventDefault()

      this.$state.needsLogo = false
      this.$fn.scrollTo(split[1])
    }
  }
}
