





































































import { gsap } from 'gsap'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Paragraph from '@/components/atoms/Paragraph.vue'
import MoreBtn from '@/components/atoms/btns/More.vue'
import Heading from '@/components/atoms/Heading.vue'

@Component({
  components: {
    Paragraph,
    Heading,
    MoreBtn,
  },
})
export default class Display extends Vue {
  @Prop()
  model!: any

  @Prop()
  image!: boolean

  @Prop()
  heading!: string

  collapsed = false

  textCollapsed = false

  $refs!: {
    more: HTMLElement
  }

  @Watch('textCollapsed')
  async onTextStateChange(collapse: boolean) {
    const { more } = this.$refs
    const height = collapse ? 0 : 'auto'
    const duration = 0.6 // ollapse ? 0 : .8
    gsap.to(more, { duration, height, ease: 'power2.out' })
  }

  getHeading(index: number) {
    const { model, heading } = this
    const { length } = model.titles
    return heading || (index === 0 && length > 1) ? 'h2' : 'h3'
  }

  hasColumns(node: string | Array<string>) {
    return node instanceof Array
  }

  mounted() {
    this.collapsed = this.textCollapsed = this.$refs.more !== undefined
  }
}
